import { render, staticRenderFns } from "./admin.vue?vue&type=template&id=05c70bfb&scoped=true&"
import script from "./admin.vue?vue&type=script&lang=js&"
export * from "./admin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05c70bfb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/workspace/yx-web-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05c70bfb')) {
      api.createRecord('05c70bfb', component.options)
    } else {
      api.reload('05c70bfb', component.options)
    }
    module.hot.accept("./admin.vue?vue&type=template&id=05c70bfb&scoped=true&", function () {
      api.rerender('05c70bfb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/admin.vue"
export default component.exports