<template>
  <div>
    <div class="disflex w60p">
      <el-button type="primary" icon="el-icon-plus" @click="createAdmin()">添加账号</el-button>
    </div>
    <el-table :data="tableData" border style="width: 100%" class="mt10">
      <el-table-column fixed label="用户名" prop="name" width="150" />
      <el-table-column fixed label="中文名" prop="nameInChinese" width="150" />
      <el-table-column label="电话" prop="mobile" width="150" />
      <el-table-column label="邮箱" prop="email" width="180" />
      <el-table-column label="角色类型" prop="roleName" width="150" />
      <el-table-column label="状态" prop="status" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.status=='active'">启用</span>
          <span v-if="scope.row.status=='forbidden'">禁用</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-tag @click="handelAct(scope.$index, -1)" class="m10 cp">编辑</el-tag>
          <el-tag @click="handelAct(scope.$index, -2)" class="m10 cp" type="danger">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="page"
      :page-size="10"
      layout="total, prev, pager, next"
      :total="totalNumber"
      class="mt10"
    ></el-pagination>
  </div>
</template>

<script>
import { adminPage, adminDelete } from "@/assets/apis/admin.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
export default {
  name: "adminList",
  data() {
    return {
      page: 1,
      act_name: "",
      search_id: "",
      tableData: [],
      totalNumber: 0,
    };
  },
  methods: {
    async deleteAdminDetail(id) {
      try {
        await ActivityAjax.deleteAdminDetail(id);
        this.$message.success("删除成功");
        this.getAdminList();
      } catch (error) {
        this.$message.error(error);
      }
    },
    async getAdminList() {
      try {
        let res = await ActivityAjax.getAdminList({
          page: this.page,
          size: 10,
        });
        console.log(res);
        this.totalNumber = res.data.total;
        this.tableData = res.data.list;
      } catch (error) {
        this.$message.error(error);
      }
    },
    handleCurrentChange(val) {
      console.log(val);
      this.page = val;
      this.getAdminList();
    },
    createAdmin() {
      this.$router.push(`/config/adminInfo`);
    },
    /**
     * 活动管理
     * @param {Number} index 用户操作索引
     * @param {Number} type 操作类型
     * @return void
     */
    handelAct(index, type) {
      const id = this.tableData[index].id || 0;
      console.log("用户id：", id);
      console.log(index, type);
      if (type === -1) {
        //编辑
        this.$router.push({
          path: "/config/adminInfo",
          query: {
            id: id,
          },
        });
      } else if (type === -2) {
        this.$confirm("确定要删除该用户吗？", "提示").then(() => {
          this.deleteAdminDetail(id);
        });
      }
    },
    /**
     * 获取用户列表
     * @param {Number} page=1 页码
     * @return void
     */
    // getAdminList() {
    //   adminPage({
    //     page: this.page,
    //     size: 10,
    //   })
    //     .then((res) => {
    //       console.log(res);
    //       this.totalNumber = res.data.total;
    //       this.tableData = res.data.list;
    //     })
    //     .catch((error) => {
    //       this.$message.error(error.msg);
    //     });
    // },
  },
  mounted() {
    this.getAdminList();
  },
};
</script>

<style lang="scss" scoped>
</style>
